
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Assignment',

  props: {
    assignmentId: {
      type: Number,
      required: true,
    },
  },
});
